<template>
  <div class="page-background">
    <AppStatus v-if="loading">
      <Spinner />
    </AppStatus>

    <AppStatus v-else-if="viewerError">
      <p>{{ $t('Hmm, something went wrong') }}</p>
      <Button @click="reload">{{ $t('Try again') }}</Button>
    </AppStatus>

    <AppStatus v-else-if="appLoaded && unauthorized">
      <p>{{ $t('We logged you out for your security. Please log in again') }}</p>
      <PageLink class="button" :to="`/login?s=${$route.fullPath}`">{{ $t('Log in') }}</PageLink>
    </AppStatus>

    <AppStatus v-else-if="appLoaded && forbidden">
      <p>{{ $t('Hmm, sorry. You don’t have access to this.') }}</p>
      <Button @click="$router.back()">{{ $t('Go back') }}</Button>
    </AppStatus>

    <div v-else-if="appLoaded && viewerLoaded">
      <AppNotification />
      <RouterView />
      <ContactSupport
        v-if="!isPEOOnboardingRoute"
        email="support@payroll.justworks.com"
        phone="6466817473"
        channel="Payroll"
        :viewer-name="viewer.okta_name"
        :viewer-email="viewer.email_home"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, provide, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { Spinner, Button, ContactSupport } from '@justworkshr/alma'
import { AppStatus } from '@justworkshr/alma'
import '@justworkshr/alma/dist/style.css'
import { defineGlobalRules } from '@justworkshr/alma/validation'
import '@justworkshr/display-icons/style.css'
import '@justworkshr/tax-forms/dist/style.css'
import { useHead } from '@unhead/vue'
import AppNotification from '@/components/AppNotification.vue'
import PageLink from '@/components/PageLink.vue'
import { t } from '@/i18n.js'
import useGlobalError from '@/use/useGlobalError.js'
import { fetching as viewerFetching, error as viewerError, loaded as viewerLoaded, viewer } from './viewer.js'

defineGlobalRules({ t })

const route = useRoute()
const { locale } = useI18n()
const { errorCode } = useGlobalError()

const appLoaded = ref(false)
const router = useRouter()
router.afterEach(() => {
  appLoaded.value = true
})

provide('locale', locale)

useHead({
  title: 'Justworks Payroll',
  meta: [{ name: 'description', content: 'Run your business with confidence' }],
})

const loading = computed(() => viewerFetching.value && !viewerLoaded.value)
const unauthorized = computed(() => errorCode.value === 401 && !route.meta?.noAuth)
const forbidden = computed(() => errorCode.value === 403)

function reload() {
  window.location.reload()
}

watch(viewerLoaded, () => {
  if (!viewerLoaded.value || !viewer.value.okta_id) return
  window.heap.loaded && window.heap.identify(viewer.value.okta_id)
  window.pendo.initialize({
    visitor: {
      id: viewer.value.okta_id,
    },
  })
})

const isPEOOnboardingRoute = computed(() => route.path.startsWith('/quoting-checklist'))
</script>

<style>
@import '@/styles/app.css';
@import '@/styles/transitions.css';
</style>

<style scoped>
.page-background {
  height: 100vh;
  background-color: var(--color-page-background);
}
</style>
