<template>
  <span className="nav-group-group">
    <span class="h3">
      <component :is="peoNavIconMap[group?.key]" class="icon" />
      {{ group.groupName }}
    </span>

    <ul style="padding: 0">
      <li v-for="navItem in filterNavItems" :key="navItem.path || navItem.altPath">
        <a v-if="navItem.altPath" :href="navItem.altPath" :aria-label="navItem.displayText" class="nav-item">
          {{ navItem.displayText }}
          <Badge v-if="navItem.badge" class="badge" :class="{ 'badge-new': navItem.badge.type === 'new' }">
            {{ navItem.badge.text }}
          </Badge>
        </a>
        <PageLink v-else :to="navItem.path" :aria-label="navItem.displayText" class="nav-item">
          {{ navItem.displayText }}
          <Badge v-if="navItem.badge" class="badge" :class="{ 'badge-new': navItem.badge.type === 'new' }">
            {{ navItem.badge.text }}
          </Badge>
        </PageLink>
      </li>
    </ul>
  </span>
</template>

<script setup>
import { computed } from 'vue'
import { Badge } from '@justworkshr/alma'
import PageLink from '@/components/PageLink.vue'
import peoNavIconMap from '../../data/peoNavIconMap.js'

const props = defineProps({
  /** @type {import('vue').PropType<PeoNavGroup>} */
  group: { type: Object, required: true },
})

const filterNavItems = computed(() => props.group.navItems?.filter((navItem) => !navItem.hidden) || [])
</script>

<style scoped>
.nav-group-group ul {
  margin-bottom: 23px;
}

.h3 {
  display: flex;
  align-items: center;
  color: var(--peo-color-nav-group-name-text);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  padding-left: 22px;
  margin-bottom: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (--tablet) {
    display: block;
    margin-bottom: 5px;
  }
}

.icon {
  content: '';
  opacity: 0.5;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 4px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 24px;
  vertical-align: sub;

  @media (--tablet) {
    width: 18px;
    height: 20px;
    margin-right: 10px;
    background-size: 18px;
  }
}

li {
  list-style: none;
}

.nav-item {
  line-height: 1.4em;
  font-weight: 400;
  font-size: 16px;
  outline: none;
  padding: 14px 0 14px 50px;
  border-bottom: none;
  transition: none;
  color: var(--peo-color-nav-item-text);
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: var(--space-md);

  @media (--tablet) {
    font-size: 14px;
    padding: 7px 0 4px 50px;
  }
}

.nav-item .badge {
  color: var(--peo-color-nav-badge-text);
  border-radius: var(--radius-lg);
  font-size: 10px;
  text-transform: uppercase;
  min-width: 40px;
  padding: 0 10px;
  height: 17px;
  font-weight: 700;
}

.nav-item .badge.badge-new {
  background-color: var(--peo-color-nav-badge-background-new);
}

:deep(.router-link-active),
.nav-item:hover {
  background: var(--peo-color-nav-item-hover-background);
  color: var(--peo-color-nav-item-hover-text);
  transition: none;
  border: none;
}

:deep(.router-link-active)::before,
.nav-item:focus-visible::before,
.nav-item:hover::before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  background: var(--peo-color-accent);
}
</style>
