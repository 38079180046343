import { datadogRum } from '@datadog/browser-rum'
import { viewer } from '@/viewer.js'

/** @typedef {enrollmentTask | agreementTask | onboardingTask} customerioStage */

/** @type {customerioStage[]} */
const customerioStages = [
  'general_company_info',
  'explore_benefits',
  'self_onboarded',
  'invite_team',
  'state_tax_info',
  'bank_account',
  'bank_account_verify',
  'manage_workers_comp',
  'csa',
  'access_dashboard',
  'key_company_contacts',
  'payroll_history_info',
]

/** @param {customerioStage} task */
export default function postTaskToCustomerIO(task) {
  if (!customerioStages.includes(task)) return

  const payload = {
    email: viewer.value.email_home,
    first_name: viewer.value.activeMembership.member.first_name,
    last_name: viewer.value.activeMembership.member.last_name,
    company: {
      name: viewer.value.activeMembership.company.entity_name,
    },
    stage: task,
  }

  // no need to await this "fire and forget" request
  fetch(import.meta.env.VITE_APP_CUSTOMERIO_EVENTS_URL, {
    method: 'POST',
    body: JSON.stringify(payload),
  }).catch((error) => {
    datadogRum.addError(error)
  })
}
