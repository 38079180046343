<template>
  <!--  eslint-disable-next-line vue/no-restricted-html-elements -->
  <router-link v-if="linkVisible(route)" v-bind="{ ...$attrs, ...$props }">
    <slot />
  </router-link>
  <slot v-else-if="allowUnlinked" />
</template>

<script setup>
import { RouterLink, useLink } from 'vue-router'
import { PEO_ROUTE_PERMISSIONS } from '@/data/peoRedirects.js'
import { featureIsOn } from '@/features.js'
import { viewerCan } from '@/viewer.js'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  ...RouterLink.props,
  allowUnlinked: {
    type: Boolean,
    default: false,
  },
})

const { route } = useLink(props)

function linkVisible(route) {
  const permissions = route.meta.permissions || PEO_ROUTE_PERMISSIONS[route.path]
  const feature = route.meta.feature
  const isPEOFeature = Boolean(feature?.includes('neonmoose_global'))
  const featureOn = isPEOFeature || feature === undefined || featureIsOn(feature)
  const hasPermission = permissions === undefined || viewerCan(...permissions)
  return featureOn && hasPermission
}
</script>
