<template>
  <nav class="site-nav" :aria-label="$t('Site')">
    <div class="logo">
      <PageLink to="/dashboard">
        <LogoPayroll
          width="120"
          height="52"
          style="color: var(--color-text)"
          aria-label="Justworks Payroll — Dashboard"
        />
      </PageLink>
    </div>
    <template v-if="!$route.meta.navless">
      <div class="active-company" :class="{ scrolled: scrollY > 0 }">
        <Row v-if="Object.keys(viewer.companies).length > 1" class="active-company-name" gap="sm">
          <IconHouseLine class="icon" />
          <CompanySelector />
        </Row>
        <div v-else class="active-company-name">
          <PageLink :to="`/dashboard`" class="home-nav-link">
            <IconHouseLine />
            <span class="company-name accent">
              {{ viewer.activeCompany.dba || viewer.activeCompany.entity_name }}
            </span>
          </PageLink>
        </div>
      </div>
      <nav ref="nav" class="main-nav" :aria-label="$t('Main')">
        <NavPayments v-if="showNavPayments" />
        <NavManage v-if="showNavManage" />
        <NavCompany v-if="showNavCompany" />
        <NavMember v-if="showNavMember" />
      </nav>
    </template>

    <div v-if="!$route.meta.navless" class="member-section">
      <ExpandableSection size="1.25rem" direction="up" reverse-order>
        <template #header>
          <div class="header">
            <b>{{ formatMemberName(member) || $t('User') }}</b>
            <PageLink :to="`/account/settings/profile-photo`" :title="$t('Edit profile photo')">
              <MemberAvatar
                :image-url="member?.profile_picture_url"
                :first-name="member?.preferred_name || member?.first_name"
                :last-name="member?.last_name"
                size="2.5rem"
                editable
              />
            </PageLink>
          </div>
        </template>
        <template #content>
          <Stack gap="md" style="margin-left: 2rem">
            <PageLink to="/logout">{{ $t('Log out →') }}</PageLink>
            <Row>
              {{ $t('Language') }}
              <FlexSpace />
              <LanguageSelector />
            </Row>
            <Row>
              {{ $t('Appearance') }}
              <FlexSpace />
              <AppearanceSwitch />
            </Row>
          </Stack>
        </template>
      </ExpandableSection>
    </div>
  </nav>
</template>

<script setup>
import { computed, ref } from 'vue'
import { Row, MemberAvatar, FlexSpace, Stack, ExpandableSection, AppearanceSwitch } from '@justworkshr/alma'
import { useScroll } from '@vueuse/core'
import CompanySelector from '@/components/CompanySelector.vue'
import LanguageSelector from '@/components/LanguageSelector.vue'
import LogoPayroll from '@/components/LogoStackedPayroll.vue'
import NavCompany from '@/components/NavCompany.vue'
import NavManage from '@/components/NavManage.vue'
import NavMember from '@/components/NavMember.vue'
import NavPayments from '@/components/NavPayments.vue'
import PageLink from '@/components/PageLink.vue'
import { formatMemberName } from '@/format.js'
import useNavPermissions from '@/use/useNavPermissions.js'
import { viewer } from '@/viewer.js'
import IconHouseLine from '~icons/ph/house-line'

const { showNavPayments, showNavManage, showNavCompany, showNavMember } = useNavPermissions()

const member = computed(() => viewer.value.activeMembership && viewer.value.activeMembership.member)

const nav = ref(null)

const { y: scrollY } = useScroll(nav)
</script>

<style scoped>
.site-nav {
  --height-logo: 132px;
  --height-active-company: 46px;
  --height-active-member: 212px;

  grid-area: main-nav;
  background-color: var(--color-nav-default);
  position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.logo {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background: var(--color-logo-background-translucent);
  backdrop-filter: blur(8px);
  height: var(--height-logo);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.main-nav {
  position: absolute;
  inset: 0;
  overflow-y: hidden;
  padding-top: calc(var(--height-logo) + var(--height-active-company));
  padding-bottom: var(--height-active-member);
}

.main-nav:hover {
  overflow-y: auto;
}

.active-company {
  position: absolute;
  background: var(--color-nav-translucent);
  backdrop-filter: blur(8px);
  z-index: 1;
  top: var(--height-logo);
  padding: var(--space-sm) var(--space-md);
  width: stretch;
}

.active-company.scrolled {
  border-bottom: 1px solid var(--color-border);
}

.active-company-name,
.active-company :deep(button) {
  font-weight: 700;
  color: var(--color-accent);
  width: 100%;
  text-align: left;
}

.active-company :deep(.icon) {
  color: var(--color-nav-blue);
}

.active-company-name .icon {
  flex-shrink: 0;
  font-size: 1.25rem;
}

.member-section {
  position: absolute;
  background: var(--color-nav-translucent);
  backdrop-filter: blur(3px);
  z-index: 1;
  left: 0;
  bottom: 0;
  right: 0;
  margin-top: auto;
  border-top: 1px solid var(--color-border);
  padding: var(--space-md);
  padding-bottom: var(--space-lg);
}

.member-section:has(details[open]) {
  backdrop-filter: blur(8px);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.home-nav-link {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  &:focus-visible {
    outline-offset: 7px;
  }
}

.home-nav-link .icon {
  color: var(--color-nav-blue);
  font-size: 1.25rem;
}

.company-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
