<template>
  <NavList :header-label="$t('People')" class="nav-list" :aria-label="$t('Manage')">
    <template #icon>
      <IconUsers />
    </template>

    <template #navItems>
      <PageLink :to="`/manage/${id}/employees`">
        {{ $t('Employees') }}
      </PageLink>
      <PageLink :to="`/manage/${id}/contractors`">
        {{ $t('Contractors') }}
      </PageLink>
      <PageLink :to="`/manage/${id}/third-party-admins`">
        {{ $t('Third party admins') }}
      </PageLink>
      <PageLink :to="`/company/${id}/time-off`">
        {{ $t('Time off') }}
      </PageLink>
    </template>
  </NavList>
</template>

<script setup>
import { computed } from 'vue'
import NavList from '@/components/NavList.vue'
import PageLink from '@/components/PageLink.vue'
import { viewer } from '@/viewer.js'
import IconUsers from '~icons/ph/users'

const id = computed(() => viewer.value.activeCompany.company_id)
</script>

<style scoped>
.nav-list {
  --nav-active-background-color: var(--color-highlight-blue);
  --nav-active-border-color: var(--color-nav-blue);
  --nav-icon-color: var(--color-nav-blue);
}
</style>
